:root {
  --loader_primary_color: #ff3d00;
  --loader_secondary_color: white;
}

* {
  padding: 0;
  margin: 0;
}

/* LOGIN PAGE STYLES */
.login-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
}
.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #002c3d;
  backdrop-filter: blur(10px);

  border-radius: 2rem;
  box-shadow: 0px 0px 0px 2px rgb(211, 136, 24);
  padding: 50px 10px;
  gap: 50px;
}
.login-logo {
  width: 22rem;
}
.login-form {
  display: flex;
  flex-direction: column;
  width: 20rem;
  gap: 1rem;
}

.login-error-message {
  color: rgb(245, 245, 245);
  /* position: absolute; */
  /* top: -3rem; */
  /* text-shadow: 0px 0px 0px rgb(225, 60, 44); */
  /* font-weight: 600; */
  background-color: rgb(186, 72, 68);
  border-radius: 0.6rem;
  box-shadow: 0px 0px 0px 1px rgb(243, 8, 8);
  padding: 1rem;
}

.login-form-input {
  padding: 1rem;
  background-color: rgb(217, 217, 217);
  outline-width: 0;
  border: none;
  border-radius: 0.6rem;
  box-shadow: 0px 0px 2px 0.1px rgb(85, 85, 85);
  transition: 0.5s;
}
.login-form-input::placeholder {
  color: rgb(172, 172, 172);
}

.login-form-input:focus {
  background-color: rgb(255, 255, 255);
  /* box-shadow: 0px 0px 2px 2px rgb(222, 133, 0); */
  box-shadow: 0px 0px 2px 2px rgb(0, 170, 222);
}

.login-form-submit {
  outline: none;
  background: rgb(253, 154, 1);
  padding: 1rem;
  color: white;
  font-size: medium;
  outline-width: 0;
  border: none;
  border-radius: 0.6rem;
  transition: background-color 0.5s;
  position: relative;
}

.login-form-submit:hover {
  cursor: pointer;
  background: rgb(222, 133, 0);
}

.login-form-submit:active {
  box-shadow: 0px 0px 2px 5px rgba(255, 181, 71, 0.426);
}

.loading {
  animation-name: loader;
  margin-right: 35px;
  font-weight: 600;
}

.loader {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  display: block;
  margin: 0px 0px;
  position: absolute;
  top: 1.55rem;
  left: 11.8rem;
  background: #fff;
  box-shadow: -12px 0 #fff, 12px 0 #fff;
  box-sizing: border-box;
  animation: shadowPulse 2s linear infinite;
}

@keyframes shadowPulse {
  33% {
    background: #fff;
    box-shadow: -12px 0 var(--loader_primary_color), 12px 0 #fff;
  }
  66% {
    background: var(--loader_primary_color);
    box-shadow: -12px 0 #fff, 12px 0 #fff;
  }
  100% {
    background: #fff;
    box-shadow: -12px 0 #fff, 12px 0 var(--loader_primary_color);
  }
}
