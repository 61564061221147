* {
  padding: 0;
  margin: 0;
  font-family: "Helvetica";
}
ul {
  list-style-type: none;
}

:root {
  --Primary-color: #002c3d;
  --Secondary-color: #f5f5f5;
  --Name-Text: #ffffff;
  --Number-Text: #707070;
}
