/* Side panel */

.side-nav-panal {
  background-color: var(--Primary-color);

  height: 100%;
}

.side-nav-panal-logo-container {
  padding: 30px 20px;
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 20px;
  border-bottom: solid 5px rgba(150, 150, 150, 0.192);
}

.side-nav-panal-logo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0px 0px 0px 5px rgb(255, 255, 255);
}
.side-nav-logo-name {
  color: white;
  font-size: 1.5rem;
}

.side-nav-panal-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem;
}
.side-nav-panal-list-item {
  border-radius: 1rem;
  padding: 1.2rem 1rem;
  transition: 0.5s;
}

.side-nav-panal-list-item:hover {
  background-color: rgba(240, 248, 255, 0.233);
}

.side-nav-panal-list-item-name {
  color: white;
  font-size: 1.1rem;
  font-weight: 600;
  padding-left: 0.5rem;
  /* background-color: rgba(240, 248, 255, 0.233); */
}

.side-nav-panal-list-item:link,
.side-nav-panal-list-item:visited {
  text-decoration: none;
}

/* Money Display */
.money-display_conatiner {
  min-width: 160px;
  width: 195px;
  height: 75px;
  border-radius: 10px;
  overflow: hidden;
  display: grid;
  grid-template-rows: 1fr 44px;
  background-color: var(--Primary-color);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.384);
}
.money-display-name-container {
  display: flex;
  align-items: center;
  margin: 0 20px;
}
.money-display-name {
  color: white;
  font-size: 16px;
}
.money-display-img {
  height: 20px;
  cursor: pointer;
}

.money-display-amount-container {
  background-color: white;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.money-display-amount {
  text-align: center;
  font-size: large;
  color: var(--Number-Text);
}
.money--display-bold {
  font-weight: 600;
}

/* BANK STORAGE COMPONENT */
.bank-storage-component-conatiner {
  min-width: 123px;
  width: 224px;
  height: 75px;
  border-radius: 10px;
  overflow: hidden;
  display: grid;
  grid-template-rows: 1fr 44px;
  background-color: var(--Primary-color);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.384);
}

.justify-bank-storage-items {
  justify-content: space-between;
}

.bank-storage-buttons-container {
  display: flex;
  gap: 20px;
  justify-content: space-between;
}
.bank-storage-button {
  background-color: transparent;
  border: none;
}

.bank-storage-button:hover {
  cursor: pointer;
}

.bank-storage-component-amount-container {
  background-color: white;
  border-radius: 5px;
  display: flex;
  justify-content: space-evenly;
  gap: 50px;
  align-items: center;
}
.bank-storage-component-amount {
  text-align: center;
  font-size: large;
  color: var(--Number-Text);
}
/* MONEY EXCHANGE */

.money-exchange-component-conatiner {
  width: 123px;
  height: 75px;
  border-radius: 10px;
  overflow: hidden;
  display: grid;
  grid-template-rows: 1fr 44px;
  background-color: var(--Primary-color);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.384);
}
.gray-money-exchange-component {
  background-color: #707070;
}

/* CREDIT CARD */
.credit-card-container {
  width: 271px;
  min-width: 271px;
  height: 105px;
  border-radius: 10px;
  overflow: hidden;
  display: grid;
  grid-template-rows: 1fr 74px;
  background-color: var(--Primary-color);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.384);
  margin-bottom: 10px;
}
.credit-card-info-container {
  background-color: white;
  padding: 0px 20px;

  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.credit-card-info {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
}
.small-info {
  font-size: 12px;
}

.credit-card-tl {
  display: flex;
  overflow: hidden;
  /* width: 50px; */
}

/* DAILY REPORT DATA TABLE */

.daily-report-data-table {
  border-radius: 10px;
  border-bottom-right-radius: 0px;
  overflow: hidden;
  box-shadow: 0px 0px 0.5px 0.5px rgb(176, 176, 176);
}

.daily-report-data-table-header {
  background-color: var(--Primary-color);
  height: 46px;
  padding-right: 17px;
}

.daily-report-data-table-item {
  height: 42px;
  background-color: white;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.54);
}
.daily-report-data-table-item:nth-child(even) {
  background-color: #f5f5f5;
}

.table-items {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
  text-align: center;
}

.data-table-header-item {
  color: var(--Name-Text);
  font-weight: bold;
}

.data-table-items {
  height: 210px;
  overflow-y: scroll;
}

.data-table-items::-webkit-scrollbar {
  width: 17px;
}

.data-table-items::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
}

.data-table-items::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 10px 10px #8e9da4;
  border-left: solid 2px transparent;
  border-right: solid 2px transparent;
}

.data-table-items::-webkit-scrollbar-thumb:hover {
  box-shadow: inset 0 0 10px 10px #73868b;
}

/* TRANSACTION HISTORY */

.transaction-table-items {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr 0.5fr;
  align-items: center;
  text-align: center;
}

.Transaction-button {
  width: 20px;
  background: transparent;
  border: none;
}
.Transaction-button:hover {
  cursor: pointer;
}
.transaction-data-height {
  height: 335px;
}

/* POPUP */
.popup-conatainer {
  background-color: rgba(0, 0, 0, 0.514);
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0px;
  top: 0px;
  display: grid;
  align-items: center;
  justify-content: center;
}

.popUp-form {
  background-color: var(--Primary-color);

  width: 21rem;
  height: 25rem;
  border-radius: 1rem;
  overflow: hidden;
  display: grid;
  grid-template-rows: 1fr 5fr;
}

.popUp-cancel-form-container {
  background-color: white;
  border-radius: 1rem 1rem 1rem 1rem;
  width: 21rem;
  padding: 1.5rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: hidden;
}

.popUp-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1rem;
}

.popUp-name {
  font-size: 1.6rem;
  color: white;
}

.popUp-cancel-button {
  background: none;
  border: none;
  height: 1.7rem;
  cursor: pointer;
}
.popUp-cancel-button-image {
  height: inherit;
}

.popUp-items-container {
  background-color: white;
  border-radius: 1rem 0rem 0rem 0rem;
  padding: 1.5rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.popUp-tranactions-container {
  display: flex;
  align-items: center;
  gap: 1.2rem;
}

.popUp-select {
  background-color: #dadada;
  /* box-shadow: inset 0px 0px 0px 1px rgb(0, 0, 0); */
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 600;
  color: #222222;
  padding: 2px;
  cursor: pointer;
}

.popUp-select > option {
  color: inherit;
  background: white;
  font-size: 0.9rem;
  outline: none;
}

/* .popUp-select {
  } */

.popUp-input-container {
  display: grid;
  gap: 0.3rem;
}
.popUp-input-name {
  padding-left: 0.2rem;
  font-weight: 600;
  color: #414141;
}
.popUp-double-Item-input {
  display: flex;
  align-items: center;
}
.popUp-input-operation-sign {
  background-color: var(--Primary-color);
  color: white;
  font-weight: 600;
  font-size: 1.5rem;
  padding: 3.9px 0.7rem;
  border-radius: 0.5rem 0rem 0rem 0.5rem;
}

.popUp-input {
  background-color: #c3c8ca;
  border: none;
  padding: 10px;
  border-radius: 0.5rem;
  outline: none;
}
.double-item-input {
  border-radius: 0px 0.5rem 0.5rem 0px;
}

.popUp-input:focus {
  background-color: rgb(241, 241, 241);
  box-shadow: 0px 0px 0px 2px rgb(30, 121, 173);
}

.popUp-input[type="number"]::-webkit-inner-spin-button,
.popUp-input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.popUp-button-container {
  display: flex;
  justify-content: space-around;
  margin-top: 1rem;
}

.popUp-button {
  border: none;
  outline: none;

  padding: 0.5rem 1.2rem;
  border-radius: 0.5rem;

  background-color: var(--Primary-color);
  color: white;

  /* box-shadow: 0px 0px 0px 1px rgb(241, 241, 241); */
  font-size: 1.1rem;
}

.button-cancel {
  /* background-color: rgb(194, 12, 12); */
  background-color: white;
  box-shadow: inset 0px 0px 0px 2px rgb(194, 12, 12);
  color: rgb(194, 12, 12);
}

.popUp-button:hover {
  cursor: pointer;
  /* background-color: rgb(0, 44, 61); */
  background-color: rgb(0, 73, 102);
  /* box-shadow: 0px 0px 10px 0px rgba(51, 51, 51, 0.466); */
}

.button-cancel:hover {
  background-color: white;
  box-shadow: inset 0px 0px 0px 2px rgb(241, 73, 73),
    0px 0px 10px 1px rgb(253, 187, 187);
  color: rgb(241, 73, 73);
  transition: 0.2s;
}

.pageloader-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pageloader {
  width: 248px;
  height: 248px;
  border: 25px solid rgb(192, 192, 192);
  border-bottom-color: var(--Primary-color);
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: loaderrotation 1s linear infinite;
}

@keyframes loaderrotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
