/* HOME  */

.home-container {
  display: grid;
  background-color: var(--Secondary-color);
  grid-template-columns: 250px 1fr;
  /* max-width: 2000px; */
  /* min-width: 1600px; */
  height: 100vh;
  margin: 0px auto;
}

/* Income Statment */

.info-container {
  overflow-y: scroll;
  /* background-color: var(--Secondary-color); */

  padding: 40px;
  color: var(--Number-Text);
  display: flex;
  flex-direction: column;
  gap: 25px;
}
/* .view-info-container { 

} */

.view-info-container-name {
  font-size: 22px;
  margin-bottom: 30px;
  display: flex;
}
.view-info-container-items-container {
  display: grid;
  grid-template-columns: 80% 1fr;
  width: 100%;
  /* justify-content: space-between; */
  gap: 60px;
}

.view-info-container-items {
  display: flex;
  gap: 25px;
  justify-content: space-between;
}
.view-info-container-item-last {
  display: flex;
  justify-content: end;
}

.daily-report-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.daily-report-buttons-container {
  display: flex;
  gap: 40px;
}

.daily-report-buttons-container-items {
  width: 52px;
  height: 25px;
  background-color: white;
  border-radius: 5px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.403);
}

.daily-report-buttons-container-items:hover {
  background-color: rgb(239, 239, 239);
  cursor: pointer;
}
.daily-report-buttons-container-items:active {
  background-color: white;
  box-shadow: 0px 0px 2px 2px rgb(67, 127, 192);
}

.daily-report-button {
  background-color: transparent;
  border: none;
  width: 15px;
}

/* BANK */
.bank-info-container-items-container {
  display: flex;
  /* gap: 157px; */
  justify-content: space-between;
}

/* CARDS */

.cards-items-container {
  display: flex;
  overflow-x: scroll;
  gap: 25px;
  padding: 20px 20px;
  /* background-color: brown; */
  /* box-shadow: inset 0px 1px 20px 0px black; */
  background-color: #eaeaea;
  border-radius: 5px;
}

.cards-container-header-button {
  background-color: var(--Primary-color);
  color: white;
  height: 27px;
  width: 124px;
  font-size: 16px;
  border-radius: 5px;
  border: none;
}
.cards-container-header-button:hover {
  cursor: pointer;
}
.cards-container-header-button:active {
  background-color: #003c54;
  box-shadow: 0px 0px 0px 3px rgb(168, 168, 168);
  /* background-color: #002c3d; */
}

.cards-items-container::-webkit-scrollbar {
  height: 12px;
}

.cards-items-container::-webkit-scrollbar-track {
  /* box-shadow: inset 0px 0px 5px grey; */
  background-color: #d6d7d7;
  border-bottom: solid 2px transparent;
  border-top: solid 2px transparent;
}

.cards-items-container::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 10px 10px #8e9da4;
  /* border-bottom: solid 2px transparent; */
  /* border-top: solid 2px transparent; */
}

.cards-items-container::-webkit-scrollbar-thumb:hover {
  box-shadow: inset 0 0 10px 10px #73868b;
}
